<template>
	<div class="container">
		<ByCancel @close="close"></ByCancel>
		<div class="content">
			<h2>聯絡點溡</h2>
			<ByInput
				variant="inputBasic input-middle"
				title="姓名"
				:required="true"
				v-model="form.name"
			/>
			<ByInput
				variant="inputBasic input-middle"
				title="手機號碼"
				:required="true"
				v-model="form.phone"
				placeholder="ex: 0912345678"
			/>
			<ByInput
				variant="inputBasic input-middle"
				title="Email"
				:required="true"
				v-model="form.email"
			/>
			<ByInput
				variant="textareaBasic textarea-middle"
				title="內容"
				class="content_text"
				type="textarea"
				:required="true"
				v-model="form.context"
				:rows="5"
			/>
			<div class="identify-code by-input">
				<ByInput
					variant="inputBasic input-middle"
					title="驗證碼"
					:required="true"
					v-model="captcha"
				/>
				<div class="identify-code__pic" @click="refrescCode">
					<ByIdentify :identifyCode="identifyCode"></ByIdentify>
				</div>
			</div>
			<ByButton stl="main1" @click="submit" :loading="submitLoading"
				>送出</ByButton
			>
		</div>
		<ByFooter />
	</div>
</template>

<script>
export default {
	name: 'Contact',
	data() {
		return {
			form: {
				name: '',
				phone: '',
				email: '',
				context: '',
			},
			captcha: '',
			identifyCodes: [],
			submitLoading: false,
		}
	},
	computed: {
		identifyCode() {
			return this.identifyCodes.join('')
		},
	},
	created() {
		this.createCode()
	},
	methods: {
		init() {
			this.form.name = ''
			this.form.phone = ''
			this.form.email = ''
			this.form.context = ''
			this.captcha = ''
		},
		async submit() {
			const { name, context } = this.form
			const checkCaptcha = this.identifyCode !== this.captcha
			if (
				name === '' ||
				context === '' ||
				!this.checkPhone() ||
				!this.checkEmail() ||
				checkCaptcha
			) {
				return this.$alert(
					checkCaptcha ? '驗證碼錯誤' : '資料格式有誤',
					'danger',
				)
			}
			this.submitLoading = true
			try {
				await this.$api.addContact(this.form)
				this.init()
				this.submitLoading = false
				this.$alert('已成功送出! 我們將儘快回覆您!', 'success')
				this.$router.back()
			} catch (err) {
				this.$alert('聯絡我們資訊寄送失敗', 'danger')
				this.submitLoading = false
			}
			this.submitLoading = false
		},
		checkPhone() {
			return this.form.phone !== ''
				? /^09[0-9]{8}$/.test(this.form.phone)
				: true
		},
		checkEmail() {
			return this.form.email !== ''
				? /^([\w\.\-]){1,64}\@([\w\.\-]){1,64}$/.test(this.form.email)
				: true
		},
		close() {
			this.$router.go(-1)
		},
		randomNum(min, max) {
			return Math.floor(Math.random() * (max - min) + min)
		},
		createCode() {
			for (let i = 0; i < 4; i++) {
				this.$set(this.identifyCodes, i, this.randomNum(0, 9))
			}
		},
		refrescCode() {
			this.createCode()
		},
	},
}
</script>

<style lang="sass" scoped>
.container
  min-height: 100vh
  position: relative

  ::v-deep .content
    +fc
    flex-direction: column
    padding: 0 20px 86px 20px
    width: 100%

    &_text
      .title
        top: 15%
        transform: translateY(-15%)

    > h2
      font-size: 27px
      letter-spacing: 1px
      margin: 5px 0 17px 0

    .btn
      margin: 0 5px 50px 5px
      border-radius: 50px
      height: 45px
      font-size: 20px
      width: 100%

    .by-input
      margin-bottom: 15px

  ::v-deep .flex
    +fc
    position: absolute
    bottom: 5px
    flex-wrap: wrap
    width: calc(100% - 40px)
    transform: translateX(20px)
    >div
      width: 100%

.identify-code
  position: relative
  width: 100%
  &__pic
    position: absolute
    right: 20px
    top: 50%
    transform: translateY(-50%)
</style>
